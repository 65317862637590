import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "management",
    path: "",
    title: "sidenav.pages.management",
    breadcrumb: false,
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "sidenav.pages.dashboard",

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "materialReceiving",
        path: `${APP_PREFIX_PATH}/recebimento-de-material`,
        title: "sidenav.pages.orders",

        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "anodizing",
    path: "",
    title: "sidenav.pages.anodizing",
    breadcrumb: false,
    submenu: [
      {
        key: "supervisor-anodizing",
        path: `${APP_PREFIX_PATH}/supervisor`,
        title: "sidenav.pages.supervisor",

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "assembly",
        path: `${APP_PREFIX_PATH}/montagem`,
        title: "sidenav.pages.assembly",

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "tanks",
        path: `${APP_PREFIX_PATH}/tanques`,
        title: "sidenav.pages.tanks",

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "disassembly",
        path: `${APP_PREFIX_PATH}/desmontagem`,
        title: "sidenav.pages.disassembly",

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "packaging-anodizing",
        path: `${APP_PREFIX_PATH}/embalagem`,
        title: "sidenav.pages.packaging",

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "conference-anodizing",
        path: `${APP_PREFIX_PATH}/conferencia`,
        title: "sidenav.pages.conference",

        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "painting",
    path: "",
    title: "sidenav.pages.painting",
    breadcrumb: false,
    submenu: [
      {
        key: "supervisor-painting",
        path: `${APP_PREFIX_PATH}/supervisor`,
        title: "sidenav.pages.supervisor",

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "checklist",
        path: `${APP_PREFIX_PATH}/checklist`,
        title: "sidenav.pages.checklist",

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "painting",
        path: `${APP_PREFIX_PATH}/pintura/montagem`,
        title: 'sidenav.pages.assembly',

        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'paintPackaging',
        path: `${APP_PREFIX_PATH}/pintura/embalagem`,
        title: 'sidenav.pages.packaging',

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "conference-painting",
        path: `${APP_PREFIX_PATH}/pintura/conferencia`,
        title: "sidenav.pages.conference",

        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;

