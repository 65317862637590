import { userService } from "services/user.service";
import { create } from "zustand";

export const useUser = create((set, get) => ({
  loadingUser: false,
  errorUser: null,
  users: [],
  user: null,
  findAllByName: async (name) => {
    set({ loadingUser: true });
    try {
      const usersFound = await userService.findAllByName(name);
      set({
        users: usersFound,
      });
    } catch (e) {
      set({
        users: [],
        errorUser: e,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  findAll: async () => {
    set({ loadingUser: true });
    try {
      const usersFound = await userService.findAll();
      set({
        users: usersFound,
      });
    } catch (e) {
      set({
        users: [],
        errorUser: e,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
  getUserByUuid: async (uuid) => {
    set({ loadingUser: true });
    try {
      const result = await userService.findUserByUuid(uuid);
      set({ user: result });
      return result;
    } catch (e) {
      set({
        user: [],
        errorUsers: e,
      });
    } finally {
      set({ loadingUser: false });
    }
  },
}));
