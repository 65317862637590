import fetch from '../auth/FetchInterceptor';

const findAll = async () => {
  const result = await fetch({
    url: '/user',
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  return result.data;
};

const findAllByName = async (name) => {
  const result = await fetch({
    url: `/user/search/${name}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  return result.data;
};

const findUserByUuid = async (uuid) => {
  const result = await fetch({
    url: `/user/${uuid}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  return result.data;
}

export const userService = {
  findAll,
  findAllByName,
  findUserByUuid
};
